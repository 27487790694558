import { captureException } from "@sentry/react";
import { BottomSheet, type BottomSheetProps } from "@stackflow/plugin-basic-ui";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";

import { userInfoStore } from "../../../entities/user/model/userInfoStore";
import { EmptyOrError } from "../empty-or-error/EmptyOrError";
import * as css from "./SafeBottomSheet.css";

interface SafeBottomSheetProps extends BottomSheetProps {}
export const SafeBottomSheet = ({
  children,
  ...props
}: SafeBottomSheetProps) => {
  return (
    <BottomSheet {...props}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ error, resetErrorBoundary }) => {
              const userId = userInfoStore.getState().user?.id;

              captureException(error, {
                user: { id: userId },
              });

              return (
                <div className={css.errorWrapper}>
                  <EmptyOrError
                    body="에러가 발생했어요"
                    CTAButton={{
                      label: "다시 시도하기",
                      onClick: () => {
                        resetErrorBoundary();
                      },
                    }}
                  />
                </div>
              );
            }}
          >
            {children}
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </BottomSheet>
  );
};
